
import { Vue, Component } from "vue-property-decorator";
import { mapMutations, mapGetters } from "vuex";
import { ResponseDataStringArray as rdsa } from "@/interfaces";
import * as types from "@/store/types";
import { sleep } from "@/utils";
@Component({
    components: {
        FilterFormPanelBase: () =>
            import("@/components/layout/FilterFormPanelBase.vue"),
        CardWidget: () => import("@/components/layout/CardWidget.vue"),
        CardStats: () => import("@/components/layout/CardStasService.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    },
    computed: {
        ...mapGetters({
            userIdentifier: types.USER_IDENTIFIER
        })
    }
    // watch: {
    //     overlay(val) {
    //         val &&
    //             setTimeout(() => {
    //                 this.overlay = false;
    //             }, 3000);
    //     }
    // }
})
export default class Summary extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }

    overlay = false;
    role = Vue.prototype.$user.get().role;
    userIdentifier?: number;
    companies: string[] = [];
    company = "";
    clients: string[] = [];
    client = "";
    installations: string[] = [];
    installs: string[] = [];
    install = "";

    params: any = {};
    listElements: any[] = [];
    services: string[] = [];
    service = "";
    elements: string[] = [];
    adisores: string[] = [];
    sustractores: string[] = [];
    selectAdisores: string[] = [];
    selectSustractores: string[] = [];
    Npadre = "0";
    MeleEs = "";

    loading = 0;
    finCadena = false;

    header = [
        { text: "N°", value: "Ncajaele" },
        {
            text: "Nombre",
            value: "Tcajaele"
        },
        {
            text: "Adisores",
            value: "suma"
        },
        {
            text: "Sustractores",
            value: "resta"
        },
        { text: "Actions", value: "actions", sortable: false }
    ];

    Ncajaele = 0;
    desserts: any = [];
    dialog = false;
    dialogDelete = false;
    editedIndex = -1;
    editedItem = {
        Ncajaele: 0,
        Tcajaele: "",
        suma: "0",
        resta: "0"
    };
    defaultItem = {
        Ncajaele: 0,
        Tcajaele: "",
        suma: "0",
        resta: "0"
    };

    formTitle() {
        return this.editedIndex === -1 ? "New Item" : "Editar Precio";
    }

    watch = {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        }
    };

    editItem(item) {
        this.editedIndex = this.desserts.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
    }

    deleteItem(item) {
        this.editedIndex = this.desserts.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
        this.Ncajaele = this.desserts[this.editedIndex]["Ncajaele"];
    }

    deleteItemConfirm() {
        this.desserts.splice(this.editedIndex, 1);
        this.deleteVariable();
        this.closeDelete();
    }

    close() {
        this.dialog = false;
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
        });
    }

    closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
        });
    }

    save() {
        if (this.editedIndex > -1) {
            Object.assign(this.desserts[this.editedIndex], this.editedItem);
        } else {
            this.desserts.push(this.editedItem);
        }
        this.Ncajaele = this.desserts[this.editedIndex]["Ncajaele"];
        // this.upgradeVariable();
        this.close();
    }

    mounted() {
        this.fetchUser();
    }

    async fetchUser() {
        if (this.role == "client") {
            this.overlay = true;
            this.loading = 1;
            try {
                const userClientResponse = await this.$api.SQLuserClient<rdsa>(
                    this.userIdentifier
                );
                if (userClientResponse.ok) {
                    this.company = userClientResponse.data[0];
                    this.client = userClientResponse.data[1];
                    this.fetchInstallation();
                }
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
                this.finCadena = true;
            } finally {
                if (this.finCadena) {
                    this.finCadena = false;
                    this.overlay = false;
                }
            }
        } else if (this.role == "admin") {
            this.fetchCompany();
        } else {
            console.log("Role undefined");
        }
    }

    async fetchCompany() {
        this.overlay = true;
        this.loading = 1;
        try {
            const companyResponse = await this.$api.SQLcompany<rdsa>();
            if (companyResponse.ok) {
                this.companies = companyResponse.data;
                this.company = this.companies[0];
                this.fetchClient();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
            this.finCadena = true;
        } finally {
            if (this.finCadena) {
                this.finCadena = false;
                this.overlay = false;
            }
        }
    }

    async fetchClient() {
        this.overlay = true;
        this.loading = 2;
        try {
            const clientResponse = await this.$api.SQLclient<rdsa>(
                this.company.split(" - ")[0]
            );
            if (clientResponse.ok) {
                this.clients = clientResponse.data;
                this.client = this.clients[0];
                this.fetchInstallation();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
            this.finCadena = true;
        } finally {
            if (this.finCadena) {
                this.finCadena = false;
                this.overlay = false;
            }
        }
    }

    async fetchInstallation() {
        this.overlay = true;
        this.loading = 3;
        try {
            const installResponse = await this.$api.SQLinstallation<rdsa>(
                this.client.split(" - ")[0]
            );
            if (installResponse.ok) {
                this.installations = installResponse.data;
                this.installs = this.installations;
                this.fetchAll();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
            this.finCadena = true;
        } finally {
            if (this.finCadena) {
                this.finCadena = false;
                this.overlay = false;
            }
        }
    }

    async fetchAll() {
        this.overlay = true;
        this.loading = 4;
        this.params = [];
        this.elements = [];
        this.adisores = [];
        this.sustractores = [];
        this.services = [];
        this.selectAdisores = [];
        this.selectSustractores = [];
        this.service = "";
        this.finCadena = true;
        this.listElements = [];

        for (const inst of this.installs) {
            try {
                const allResponse = await this.$api.SQLallgenserv<rdsa>(
                    inst.split(" - ")[0]
                );
                if (allResponse.ok) {
                    this.listElements = this.listElements.concat(
                        allResponse.data
                    );
                }
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            }
        }
        this.getVariable();
        this.install = this.installations[0];
        this.toJSON();
    }

    toJSON() {
        try {
            for (const elemento of this.listElements) {
                //([inst, box, analyzer, lment, modbus,f"{Ncajaeletipo} - {Prectip[str(Ncajaeletipo)]}",0,0,None])#Npadreconsumo, bit, nPadre
                this.params[elemento[3]] = {
                    install: elemento[0],
                    box: elemento[1],
                    analyzer: elemento[2],
                    modbus: elemento[4],
                    servicio: elemento[5],
                    Npadre: elemento[6],
                    jerar: elemento[7],
                    nPadre: elemento[8]
                };
                this.services.push(elemento[5]);
            }
            const aux: string[] = [];
            this.services.forEach(function(item) {
                if (aux.indexOf(item) < 0) {
                    aux.push(item);
                }
            });
            this.services = aux;
            this.services.sort();
            this.service = this.services[0];
            this.getElements();
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    getElements() {
        this.elements = [];
        this.adisores = [];
        this.sustractores = [];
        this.selectAdisores = [];
        this.selectSustractores = [];
        for (const i in this.params) {
            if (
                this.params[i]["servicio"] == this.service &&
                this.installs.includes(this.params[i]["install"])
            ) {
                this.elements.push(i);
            }
        }
        this.adisores = Object.assign([], this.elements);
        this.sustractores = Object.assign([], this.elements);
        this.elements.push("0");
    }

    setElements() {
        this.adisores = Object.assign([], this.elements);
        this.sustractores = Object.assign([], this.elements);
        this.adisores.pop();
        this.sustractores.pop();
        this.selectAdisores.forEach(i => {
            const aux = this.sustractores.indexOf(i);
            this.sustractores.splice(aux, 1);
        });
        this.selectSustractores.forEach(i => {
            const aux = this.adisores.indexOf(i);
            this.adisores.splice(aux, 1);
        });
    }

    async saveElements() {
        if (this.selectAdisores.length > 0 && (this.selectAdisores.length + this.selectSustractores.length)>1) {
            const data = {
                empresa: this.company,
                cliente: this.client,
                install: this.install,
                service: this.service,
                meleEs: this.MeleEs,
                Npadre: this.Npadre,
                adisor: this.selectAdisores,
                sustract: this.selectSustractores
            };
            try {
                const allResponse = await this.$api.saveAlgebra<rdsa>(data);
                if (allResponse.ok) {
                    this.showError("Variable correctamente guardada");
                    this.getVariable();
                }
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            }
        } else {
            this.showError("Hacen falta elementos");
        }
    }

    async getVariable() {
        try {
            const varResponse = await this.$api.getAlgebra<rdsa>(
                this.client.split(" - ")[0]
            );
            if (varResponse.ok) {
                this.desserts = varResponse.data;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            if (this.finCadena) {
                this.finCadena = false;
                this.overlay = false;
            }
        }
    }

    async deleteVariable() {
        this.setProgressBar(true);
        try {
            const deleteResponse = await this.$api.deleteAlgebra<rdsa>(
                this.Ncajaele
            );
            if (deleteResponse.ok) {
                this.showError("Variable Eliminado");
                this.getVariable();
                this.Ncajaele = 0;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setProgressBar(false);
        }
    }

    resetForm0() {
        this.clients = [];
        this.client = "";
        this.installations = [];
        this.installs = [];
        this.install = "";
        this.params = {};
        this.listElements = [];
        this.services = [];
        this.service = "";
        this.elements = [];
        this.adisores = [];
        this.sustractores = [];
        this.selectAdisores = [];
        this.selectSustractores = [];
        this.Npadre = "0";
        this.MeleEs = "";
    }

    resetForm1() {
        this.installations = [];
        this.installs = [];
        this.install = "";
        this.params = {};
        this.listElements = [];
        this.services = [];
        this.service = "";
        this.elements = [];
        this.adisores = [];
        this.sustractores = [];
        this.selectAdisores = [];
        this.selectSustractores = [];
        this.Npadre = "0";
        this.MeleEs = "";
    }

    resetForm2() {
        this.install = "";
        this.params = {};
        this.listElements = [];
        this.services = [];
        this.service = "";
        this.elements = [];
        this.adisores = [];
        this.sustractores = [];
        this.selectAdisores = [];
        this.selectSustractores = [];
        this.Npadre = "0";
        this.MeleEs = "";
    }
}
